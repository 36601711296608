<script>
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      isLoading : false,
    };
  },
  validations: {
    email: { required, email }
  },
  methods: {
    tryToReset() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.isLoading = true;
        let data = { email : this.email}
        axios.post(`v1/forgot-password`,data)
        .then((res) => {
          if(res.data.success === false) {
            this.isLoading = false;
            Swal.fire("Failed", res.data.message, "error");
          } else {
            this.isLoading = false;
            Swal.fire("Success", "Please check your email for new password", "success");
            this.$router.push('/login')
          }
        })
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div class="login-box">
                      <div class="text-center">
                        <div>
                          <div class="logo">
                            <img src="@/assets/images/logo-2.png" height="50" alt="logo" />
                          </div>
                        </div>

                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Reset your password to Adaremit.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                        >{{error}}</b-alert>

                        <form class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email is required.</span>
                              <span v-if="!$v.email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              :disabled="isLoading"
                            >
                            <span v-if="isLoading"><b-spinner small v-if="isLoading" class="m+2" role="status"></b-spinner></span>
                            <span v-else>Reset</span>
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                          >Log in</router-link>
                        </p>
                        <p>
                          © 2022 Adaremit
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>